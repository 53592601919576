<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <usuario-form
          :usuario-idusuario="usuarioIdusuario"
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import UsuarioForm from '../components/UsuarioForm'
import Data from './UsuarioEditData'
import { get } from 'vuex-pathify'

export default {
  components: {
    UsuarioForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdusuario: get('usuario/idusuario'),
    usuarioLogin: get('usuario/login'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Cambiar contraseña'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      await Data.update(
        this, this.usuarioIdusuario, this.formData
      )
      this.$alert.showSnackbarSuccess('Contraseña cambiada.')
      this.$appRouter.go(-1)
    },
  },
}
</script>
