import { currentDateTime } from '@/utils/date'

export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'usuariosistema.update',
      {
        values: {
          idusuariosistema: id,
          password: formData.repetir,
          fcambiopass: currentDateTime()
        },
      },
    )
  },
}
